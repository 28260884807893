<template>
  <main v-if="result" class="pay-result">
    <div v-if="isSign">
      <div class="header" :class="result !== 'success' && 'high'">
        <svg-icon class="pay-result-icon" :class="result !== 'success' && 'icon-not-success'"
          :iconClass="payResultIcon"></svg-icon>
        <div class="pay-result-text" :class="result !== 'success' && 'not-success-text'">
          {{ getSignText }}
        </div>
      </div>
    </div>
    <template v-else>
      <div class="header" :class="result !== 'success' && 'high'">
        <svg-icon class="pay-result-icon" :class="result !== 'success' && 'icon-not-success'"
          :iconClass="payResultIcon"></svg-icon>
        <div class="pay-result-text" :class="result !== 'success' && 'not-success-text'">
          {{ payResultText }}
        </div>
        <div class="pay-result-desc" v-if="result !== 'success'">
          支付遇到问题，请尝试重新支付
        </div>
      </div>
      <div class="price-wrap" v-if="result === 'success'">
        <div class="price"><span>¥</span>{{ amount }}</div>
        <div class="pay-method">{{ source }}</div>
        <div class="discount" v-if="!!discount || payInfo.coupon_real_price">
          <div>
            <span>原价</span>
            <span class="face-value">¥{{ itemInfo.face_value || payInfo.total_origin_price }}
            </span>
          </div>
          <div v-if="!!discount">
            <span>优惠</span>
            <span>会员优惠¥{{ discount }}</span>
          </div>
        </div>
      </div>
    </template>

    <open-member-dialog :is_dialog="false" page="pay_result" />

    <div class="footer" v-if="!isAlipay">
      <template v-if="result === 'success'">
        <van-button :class="payBtnColor" class="pay-result-btn no-border" v-if="category_type === 'vip'" block round
          type="info" v-ans="{
            event_id: 'pay_result_btn_click1',
          }" @click="toHome">{{
            namespace === "pingan_gj" ? "查看会员卡" : "立即查看权益"
          }}</van-button>
        <van-button class="pay-result-btn no-border" :class="payBtnColor" v-ans="{
          event_id: 'pay_result_btn_click1',
        }" v-else block type="info" round @click="toOrder">立即查看权益</van-button>
      </template>
      <van-button class="pay-result-btn" v-else-if="category_type === 'vip' && namespace === 'pingan_gj'" block round
        type="info" v-ans="{
          event_id: 'pay_result_btn_click2',
        }" @click="toHome">立即返回</van-button>
      <van-button class="pay-result-btn" v-if="$getConfigValue('INDEX_URL') && namespace !== 'pingan_gj'" block round
        plain v-ans="{
          event_id: 'pay_result_btn_click2',
        }" @click="toHome">返回首页</van-button>
    </div>
  </main>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Decimal from "decimal.js";

import { getOrderList, getItemInfo } from "@/apis/home";
import { queryOrder } from "@/apis";
import ans from "@/utils/ans";
import OpenMemberDialog from "@/components/OpenMemberDialog";
import { getProjectDb } from "@/utils";

export default {
  components: {
    OpenMemberDialog,
  },
  data() {
    return {
      count: 0,
      result: "",
      category_type: this.$route.query.category_type,
      order_number: this.$route.query.order_number,
      payInfo: {},
      itemInfo: {},
      amount: 0,
      source: "",
    };
  },
  computed: {
    ...mapState(["namespace", "isMiniProgram", "namespaceInfo", "isAlipay"]),
    ...mapGetters(["getConfigValue", "payBtnColor"]),
    isSign() {
      return (
        this.$route.query.method === "alipay.user.agreement.page.sign.return"
      );
    },
    getSignText() {
      return this.$route.query.status === "NORMAL" ? "签约成功" : "签约失败";
    },
    payResultText() {
      return {
        success: "支付成功",
        fail: "支付失败",
        unpaid: "未支付",
      }[this.result];
    },
    payResultIcon() {
      const isGold = this.getConfigValue("PAY_PAGE_IS_GOLD");
      return isGold
        ? `pay-result-${this.result}-gold`
        : `pay-result-${this.result}-black`;
    },
    // 计算优惠金额
    discount() {
      if (!this.itemInfo.face_value || !this.payInfo.item_price) {
        return 0;
      }
      return new Decimal(this.itemInfo.face_value)
        .minus(new Decimal(this.payInfo.item_price))
        .toNumber();
    },
  },
  mounted() {
    ans.pageView(this.$route.meta.page, {
      namespace: this.namespace,
      source: this.source,
      order_number: this.order_number,
    });
    if (this.namespace === "keep") {
      window.KeepSensorsVpublic &&
        window.KeepSensorsVpublic.track("page_sunny_order_pay_view", {
          price: this.$route.query.price,
          pay_type: "1",
          from: "gogo",
        });
    }
    if (!this.isSign) {
      this.loading = this.$loading("正在同步结果");
      this.fetchOrder();
    } else {
      this.result =
        this.$route.query.status === "NORMAL" ? "success" : "failed";
    }
  },
  methods: {
    toHome() {
      // 小程序返回首页
      if (this.isMiniProgram && this.category_type === "vip") {
        if (this.result !== "success") {
          const url = this.$getConfigValue("WECHAT_MIN_PAY_RETURN_URL");
          if (url) {
            wx.miniProgram.navigateTo({ url });
          } else {
            wx.miniProgram.navigateBack({ delta: 100 });
          }
          return;
        }
      }
      const url = this.$getConfigValue("INDEX_URL");
      if (url.startsWith("/")) {
        this.$router.replace(url);
      } else {
        this.$jumpUrl(url);
      }
    },
    toOrder() {
      const { order_number } = this.$route.query;
      this.$router.push(
        `/orderDetail?order_number=${order_number}&from=payResult`
      );
    },

    async fetchOrder() {
      try {
        const res = await queryOrder({ order_number: this.order_number });
        const orderDetail = await getOrderList({
          order_number: this.order_number,
        });
        this.payInfo = JSON.parse(orderDetail.data.res_list[0].pay_info);
        const itemInfo = await getItemInfo({
          item_code: this.payInfo.item_code,
          benefit_type: this.payInfo.product_code,
          project_db: getProjectDb(this.namespace),
        });
        this.itemInfo = itemInfo;
        this.amount = orderDetail.data.res_list[0].amount;
        this.source = orderDetail.data.res_list[0].source;
        if (res.data.trade_state === "unpaid") {
          if (this.count < 3) {
            this.count++;
            this.timer = setTimeout(this.fetchOrder, 500);
          } else {
            this.count = 0;
            this.result = "fail";
            this.loading.clear();
          }
        } else if (res.data.trade_state === "paid") {
          this.result = "success";
          this.loading.clear();
        } else {
          this.result = "fail";
          this.loading.clear();
        }
      } catch (e) {
        this.loading.clear();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.pay-result {
  height: 100vh;
  background: #fff;
  text-align: center;
  padding: 0 24px;
  overflow: hidden;
}

.header {
  margin-top: 85px;

  .pay-result-icon {
    font-size: 48px;

    &.icon-not-success {
      font-size: 64px;
      margin-bottom: 20px;
    }
  }

  .pay-result-text {
    font-size: 16px;
    font-weight: 500;
    color: @text-primary-color;
    line-height: 22px;
    margin-top: 4px;
    font-family: PingFangSC-Medium, PingFang SC;

    &.not-success-text {
      font-size: 22px;
      font-weight: 500;
      color: #333333;
      line-height: 30px;
      margin-top: 0px;
    }
  }

  .pay-result-desc {
    font-size: 11px;
    color: #999999;
    line-height: 16px;
    margin-top: 6px;
    font-family: PingFangSC-Regular, PingFang SC;
  }
}

.high {
  margin-top: 181px;
}

.price-wrap {
  margin-top: 51px;

  .price {
    font-size: 44px;
    font-weight: 600;
    color: #000000;
    line-height: 62px;
    position: relative;

    span {
      font-size: 30px;
      line-height: 45px;
      vertical-align: text-top;
    }
  }

  .pay-method {
    font-size: 13px;
    color: @text-primary-color;
    line-height: 18px;
  }

  .discount {
    border-top: 1px solid #f1f1f1;
    margin-top: 23px;
    padding-top: 10px;

    >div {
      display: flex;
      justify-content: space-between;
      margin-bottom: 4px;
      color: #ff5001;
      line-height: 17px;

      &:first-child {
        font-size: 12px;
        color: #888888;

        .face-value {
          text-decoration: line-through;
        }
      }
    }
  }
}

.footer {
  position: fixed;
  bottom: 51px;
  width: calc(100% - 67px);
  right: 33px;
  left: 34px;

  .pay-result-btn {
    font-size: 17px;
    color: @text-primary-color;
    border: @text-light-color solid 0.5px;
    background: #fff;
    height: 40px;
    margin-top: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
  }

  .no-border {
    border: none;
  }
}
</style>