<template>
  <div
    class="product-item"
    @click="
      toDetail(item, activity.start_time, activity.start_time, activity.status)
    "
    :key="item.item_code"
    v-ans="{
      event_id: 'seckill_jump_detail_click',
      namespace,
      item_code: item.item_code,
      activity_name: activityQueryName,
      start_time: activity.start_time,
      activity_status: activity.status,
    }"
  >
    <div class="product-tag">{{ tag }}</div>

    <div class="product-img-wrap">
      <van-image class="product-img" fit="contain" lazy-load :src="item.image" />
      <div class="product-sales" v-if="activity.status === 'start'">
        <div :class="['product-progress']">
          <div
            class="product-progress-bar"
            :style="`width: calc(${100 - getWidth(item)}%)`"
          ></div>
        </div>
        <div class="product-sales-text">剩余{{ 100 - getWidth(item) }}%</div>
      </div>
    </div>

    <div class="product-content">
      <div class="product-title">
        <p class="product-name ellipsis">{{ item.title }}</p>
      </div>
      <div class="price-wrap">
        <div class="price-text">
          <div>已补</div>
          <div>
            ¥<span>{{ getValue(item) }}</span>
          </div>
        </div>
      </div>
      <div :class="['product-context', getComputedStatus(item, 'actionClass')]">
        <div class="spike"><span>¥</span>{{ item.activity_price }}</div>
        <div class="action-wrap">
          <div class="text">
            <span :class="computedActionIcon(item)">{{ getStartTime }} </span>
            {{ getBtnText(item) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as dayjs from "dayjs";
import { ACTIVITY_ENUM } from "@/constants";
import Decimal from "decimal.js";
export default {
  props: {
    statueClassEnum: Object,
    detail: Object,
    activity: Object,
    item: Object,
    tag: String,
    namespace: String,
    activityQueryName: String,
    toDetail: Function,
  },
  computed: {
    getStartTime() {
      return dayjs(this.activity.start_time).format("HH:mm");
    },
    getComputedStatus() {
      return function (item = {}, type) {
        if (item?.stock?.user_daily_stock === 0 || this.getWidth(item) === 100)
          return this.statueClassEnum["end"][type];
        return this.statueClassEnum[this.activity.status][type];
      };
    },

    computedActionIcon() {
      return function (item) {
        if (item.stock?.user_daily_stock === 0 || this.getWidth(item) == 100)
          return "disable";
        if (this.activity.status === "unstart") return "unstart";
        return "none";
      };
    },
  },
  methods: {
    getValue(item) {
      return new Decimal(item.face_value).minus(item.activity_price);
    },
    getBtnText(item) {
      if (item.stock?.user_daily_stock === 0) {
        return "已抢购";
      }
      if (this.getWidth(item) == 100) {
        return "售罄";
      }
      if (this.activity.status === "unstart") {
        return "开抢";
      }
      return ACTIVITY_ENUM[this.activity.status].btnName;
    },
    getWidth({ stock }) {
      if (!stock || !Object.keys(stock).length || !stock.session_stocks) {
        return 0;
      }
      return Number(
        ((stock.session_used_stocks / stock.session_stocks) * 100).toFixed(0)
      );
    },

    getDisabled(item, activity) {
      return (
        this.getWidth(item) == 100 ||
        activity.status !== "start" ||
        item.stock?.user_daily_stock === 0
      );
    },
    getKillState(item) {
      if (item.stock?.user_daily_stock === 0) {
        return "isBuy";
      }
      if (this.getWidth(item) == 100) {
        return "sellOff";
      }
      return "";
    },
  },
};
</script>

<style lang="less" scoped>
.product-item {
  background: #ffffff;
  box-sizing: border-box;
  display: flex;
  position: relative;
  border-radius: 8px;
  padding: 8px 12px 12px 14px;
  &:not(:first-child) {
    margin-top: 16px;
  }
}

.product-sales {
  display: flex;
  align-items: center;
  margin-top: 8px;
  justify-content: space-between;
}

.product-progress {
  width: 66px;
  border-radius: 3px;
  height: 5px;
  box-sizing: border-box;
  position: relative;
  margin-right: 6px;
  background-color: #fff;
  border: 1px solid #ec3d5b;
  line-height: 14px;
  align-items: center;
}
.face_value {
  line-height: 17px;
  color: #999;
}

.product-sales-text {
  font-size: 10px;
  flex-shrink: 0;
  color: #ec3d5b;
}

.product-progress-bar {
  position: absolute;
  left: -1px;
  top: -1px;
  height: 5px;
  border-radius: 3px;
  background-color: #ec3d5b;
  z-index: 100;
  transition: all 0.3s;
}
.product-tag {
  position: absolute;
  left: 0;
  top: 0;
  height: 16px;
  line-height: 16px;
  background: linear-gradient(123deg, #fd2e81 0%, #fb311c 100%);
  border-radius: 8px 0px 8px 0px;
  color: #fff;
  padding: 0 6px;
  z-index: 10;
}
.product-img-wrap {
  width: 120px;
  margin-right: 38px;
  margin-top: 12px;
}

.product-img {
  height: 78px;
}

.product-content {
  flex: 1;
}

.product-title {
  display: flex;
  justify-content: space-between;
  .product-name {
    font-size: 16px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 22px;
    margin-bottom: 8px;
  }

  .product-type {
    border-radius: 4px;
    padding: 5px 12px;
    font-size: 10px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    line-height: 14px;
    &.unstart {
      background: #e9f8eb;
      color: #16c075;
    }
    &.start {
      color: #ec3d5b;
      background: #fde0df;
    }
    &.end {
      color: #ec3d5b;
      background: #fde0df;
    }
  }
}

.price-wrap {
  background-image: url("../../assets/images/seckill-price-wrap.png");
  background: url("~@/assets/images/price-wrap_bg.png") no-repeat;
  height: 51px;
  color: #b05229;
  background-size: contain;
  text-align: center;
  line-height: 17px;
  margin-bottom: 4px;
  span {
    line-height: 15px;
    font-weight: bold;
    font-size: 14px;
  }
}

.product-context {
  height: 33px;
  border-radius: 8px;
  position: relative;
  display: flex;
  background: url("~@/assets/images/seckill_sm_btn.png") no-repeat;
  background-size: cover;
  align-items: center;
  &.disable {
    background-image: url("~@/assets/images/seckill_sm_btn_disabled.png");
    .action-wrap {
      color: #fff;
    }
  }
  .spike {
    flex: 1;
    flex-wrap: nowrap;
    color: #ffffff;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    font-family: ArialMT;
    span {
      font-size: 12px;
      margin-right: 2px;
      font-weight: normal;
    }
  }

  .action-wrap {
    flex: 1;
    text-align: center;
    font-size: 14px;
    font-weight: normal;
    color: #fbe2c6;
    .none,
    .disable {
      display: none;
    }
  }
}
</style>