<template>
  <div class="luck-draw-body">
    <div class="gashapon-container">
      <van-image
        class="luck-draw-bg"
        :src="activityInfo.special_info.header_img"
      />
      <div class="luck-draw-component" ref="gashaponRef"></div>
      <div class="draw-btn-wrap">
        <div class="draw-btn1" @click="drawHandleThrottle">
          {{ activityInfo.special_info.start_draw_text || "开始抽奖" }}
          {{ remainingTimes > 0 ? `X${remainingTimes}` : "" }}
        </div>
      </div>
    </div>
    <Dialog
      v-model="showDialog"
      :activityReward="activityReward"
      :type="type"
      :openPrizePopup="openPrizePopup"
    />
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  onMounted,
  ref,
} from "@vue/composition-api";
import throttle from "lodash.throttle";
import Dialog from "./Dialog";
import Vue from "vue";
import { pxToRemPx, sleep } from "@/utils";
import * as Matter from "matter-js";
import { random } from "@/utils";

const vm = Vue.prototype;
export default defineComponent({
  inheritAttrs: false,

  components: {
    Dialog,
  },
  props: {
    activityInfo: Object,
    activityReward: Object,
    beforeDraw: Function,
    openPrizePopup: Function,
    afterDraw: Function,
    type: String,
    remainingTimes: Number,
  },
  setup(props) {
    const showDialog = ref(false);
    const gashaponRef = ref();
    const wall = 20;

    const width = computed(() =>
      pxToRemPx(props.activityInfo.special_info.width + wall * 2)
    );
    const height = computed(() =>
      pxToRemPx(props.activityInfo.special_info.height + wall * 2)
    );

    const run = ref(true);
    const balls = [];

    const drawHandle = async () => {
      if (run.value) {
        return;
      }
      const res = await props.beforeDraw(true);
      console.log(res, "beforeDraw");
      if (!res) {
        return;
      }
      run.value = true;
      await start();
      run.value = false;
      showDialog.value = true;
      await props.afterDraw();
    };
    const drawHandleThrottle = throttle(drawHandle, 3000, { trailing: false });

    const sourceData = computed(() => {
      const goods_choice = props.activityInfo.special_info.goods_choice || {};
      return Object.entries(goods_choice).map(([k, v], index) => {
        return {
          name: v.prize_name,
          item_code: k,
          path: v.img,
        };
      });
    });

    const gameInit = (baseImgWidth) => {
      const Engine = Matter.Engine,
        Render = Matter.Render,
        World = Matter.World,
        Bodies = Matter.Bodies;

      const w = width.value;
      const h = height.value;
      const engine = Engine.create();

      const render = Render.create({
        element: gashaponRef.value,
        engine: engine,
        options: {
          width: w,
          height: h,
          wireframes: false,
          background: "transparent", // 设置背景为透明
        },
      });
      const grounds = [];
      // 墙体  isStatic 静止  矩形坐标为元素中心位置
      // 上 右 下 左
      const wallsPoint = [
        [w / 2, wall / 2, w, wall],
        [w - wall / 2, h / 2, wall, h],
        [w / 2, h - wall / 2, w, wall],
        [wall / 2, h / 2, wall, h],
      ];
      wallsPoint.forEach((wall) => {
        grounds.push(
          Bodies.rectangle(...wall, {
            isStatic: true,
            restitution: 0.6,
            render: {
              fillStyle: "transparent",
            },
          })
        );
      });
      sourceData.value.forEach((item, i) => {
        const b = 28 * props.activityInfo.special_info.prizeWidth || 1;
        // 随机半径
        const r = b + ~~(Math.random() * 10);
        const p = r / 2;
        balls.push(
          Bodies.circle(p + i * p, 20, r, {
            // inertia: 1000000, // 设置较大的惯性以减少旋转
            restitution: 0.9, //回弹系数
            // density: 0.4, // 密度
            mass: random(15, 20), // 质量
            angle: -Math.PI * Math.random(), // 角度
            render: {
              sprite: {
                texture: item.path, // 贴图
                xScale: (r * 2) / baseImgWidth, // 缩放比例
                yScale: (r * 2) / baseImgWidth,
              },
            },
          })
        );
      });

      World.add(engine.world, [...grounds, ...balls]);
      Matter.Runner.run(engine);
      Render.run(render);
      // 限制小球的位置
      Matter.Events.on(engine, "beforeUpdate", function (event) {
        balls.forEach(function (ball) {
          if (ball.position.x > render.options.width - wall) {
            Matter.Body.setPosition(ball, {
              x: render.options.width - wall,
              y: ball.position.y,
            });
          }
          if (ball.position.x < wall) {
            Matter.Body.setPosition(ball, { x: wall, y: ball.position.y });
          }
          if (ball.position.y > render.options.height - wall) {
            Matter.Body.setPosition(ball, {
              x: ball.position.x,
              y: render.options.height - wall,
            });
            // 掉到地板下面的小球  重新给一个向上的力
            Matter.Body.applyForce(ball, ball.position, {
              x: 0,
              y: -0.05,
            });
          }
          if (ball.position.y < wall) {
            Matter.Body.setPosition(ball, { x: ball.position.x, y: wall });
          }
        });
      });
      run.value = false;
    };

    function ballRun(timeout) {
      return new Promise((resolve) => {
        setTimeout(() => {
          balls.forEach(function (ball, index) {
            var forceMagnitude = 0.15 * ball.mass;
            Matter.Body.applyForce(ball, ball.position, {
              x: ((Math.random() - 0.5) * forceMagnitude) / 2,
              y: (Math.random() - 0.5) * forceMagnitude,
            });
            // 5秒后停止小球
          });
          resolve();
        }, timeout);
      });
    }

    async function start() {
      run.value = true;
      ballRun(300);
      for (let i = 0; i < 10; i++) {
        await ballRun(300);
      }

      balls.forEach(function (ball) {
        Matter.Body.setVelocity(ball, { x: 0, y: 0 });
        Matter.Body.setAngularVelocity(ball, 0);
      });
      await sleep(1000);
    }

    onMounted(() => {
      const firstImg = sourceData.value[0].path;
      var img = new Image();
      img.onload = function () {
        const baseImgWidth =
          props.activityInfo.special_info.baseImgWidth || img.width;
        console.log(baseImgWidth);
        gameInit(baseImgWidth);
      };
      img.src = firstImg;
    });

    return {
      drawHandleThrottle,
      showDialog,
      pxToRemPx,
      width,
      height,
      gashaponRef,
    };
  },
});
</script>

<style lang="less">
.draw-btn1 {
  font-size: 13px;
  position: absolute;
  bottom: 79px;
  left: 88px;
  color: #fff;
  line-height: 40px;
  width: 94px;
  text-align: center;
  text-shadow: 0px 1px 1px #de4d89;
}
.show-prize-egg {
  font-size: 13px;
  position: absolute;
  top: 520px;
  right: 94px;
  color: #fff;
  line-height: 40px;
  width: 94px;
  text-align: center;
  text-shadow: 0px 1px 1px #3e4cbe;
}
</style>
