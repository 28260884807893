<template>
  <div class="wrap">
    <div class="to-use" @click="handleToUse" v-if="isMember">
      我已购买，点击查看权益
    </div>
    <div v-for="item in catesgorys" :key="item.id" :class="item.display_code">
      <span class="unit">¥</span>
      <span class="price">{{ computedPrice(item.desc, "price") }}</span>
      <div class="tips">立省¥{{ computedPrice(item.desc, "discount") }}</div>
      <div
        class="btn"
        @click="handleOpenUp(item.display_code)"
        v-ans="{ event_id: 'visitor_click_buy' }"
      >
        立即开通
      </div>
    </div>
    <div class="service" @click="toServiceLink"></div>
    <div class="rule-btn" @click="showRule = !showRule">
      活动规则
      <van-icon name="arrow-down" class="arrow" v-if="!showRule" />
      <van-icon name="arrow-up" class="arrow" v-else />
    </div>
    <div class="rule-wrap" v-if="showRule" v-html="namespaceInfo.rule"></div>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  onMounted,
  computed,
} from "@vue/composition-api";

import { useRouter, useStore } from "@/components/use/base";
import useVisitorCategory from "@/use/useVisitorCategory";
import { toPayV2 } from "@/helper/pay";
import LoginDialog from "@/components/loginDialog";

export default defineComponent({
  setup() {
    const router = useRouter();
    const store = useStore();
    const namespace = store.state.namespace;
    const isMember = computed(() => store.state.isMember);
    const namespaceInfo = computed(() => store.state.namespaceInfo);
    const isLogin = computed(() => !!store.state.token);

    const {
      fetchCategory,
      fetchPackage,
      fetchPaymethod,
      packages,
      catesgorys,
      currentMethod,
    } = useVisitorCategory();

    const showRule = ref(false);

    const computedPrice = (value, type) => {
      return value.split("｜")[{ price: 0, discount: 1 }[type]];
    };

    const handleOpenUp = async (code) => {
      const currentPackage = packages.value.filter(
        (item) => item.type === code
      )[0];

      if (!isLogin.value) {
        await login();
      }
      await toPayV2(currentPackage, currentMethod.value.channel, {
        activity: currentPackage.item_code,
      });
    };

    const login = async () => {
      await LoginDialog.show({
        title: "手机号登录后使用",
        className: `__${namespace}-login`,
        needCheck: true,
      });
    };

    onMounted(async () => {
      await Promise.all([fetchCategory(), fetchPackage(), fetchPaymethod()]);
    });

    const handleToUse = () => {
      router.push("./index");
    };

    const toServiceLink = () => {
      window.open("https://oakvip.s2.udesk.cn/im_client/?web_plugin_id=60193");
    };

    return {
      handleToUse,
      computedPrice,
      toServiceLink,
      showRule,
      isMember,
      namespaceInfo,
      catesgorys,
      handleOpenUp,
    };
  },
});
</script>


<style lang="less" scoped>
.wrap {
  position: relative;
  background: url(https://common-h5-dev.xiangshuheika.com/static/upload/2025021296b7e03d0d806.png)
    #ffa347;
  background-repeat: no-repeat;
  background-size: 100%;
  min-height: calc(100vh - 29px);
  padding-bottom: 29px;
  overflow: hidden;
}

.LRTSMG01 {
  width: 145px;
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  align-items: end;
  left: 208px;
  top: 419px;
}

.LRTSMG03 {
  width: 145px;
  display: flex;
  position: absolute;
  flex-wrap: wrap;
  align-items: end;
  top: 419px;
  left: 33px;
}

.service {
  position: absolute;
  background: url(https://common-h5-dev.xiangshuheika.com/static/upload/2025021320df8b659dd9.png);
  background-repeat: no-repeat;
  background-size: contain;
  box-shadow: 0px 3px 6px 0px rgba(205, 90, 13, 0.65);
  top: 518px;
  right: 11px;
  height: 48px;
  width: 48px;
  border-radius: 24px;
}

.LRTSMG12 {
  position: absolute;
  top: 280px;
  left: 27px;
  display: flex;
  position: absolute;
  flex-wrap: nowrap;
  align-items: end;
  .unit {
    font-weight: 600;
    font-size: 22px;
    color: #000000;
    line-height: 30px;
  }
  .price {
    font-weight: 600;
    font-size: 41px;
    color: #000000;
    line-height: 41px;
    margin-left: 4px;
  }
  .tips {
    background: url(https://common-h5-dev.xiangshuheika.com/static/upload/2025021199ef70279a1d3.png);
    background-repeat: no-repeat;
    background-size: 100%;
    width: 66px;
    height: 20px;
    padding-left: 9px;
    box-sizing: border-box;
    margin-bottom: 8px;
    margin-left: 7px;

    font-size: 10px;
    color: #ffffff;
    line-height: 12px;
    text-align: center;
    line-height: 20px;
  }
  .btn {
    margin-left: 19px;
    margin-bottom: 4px;
  }
}

.btn {
  background: linear-gradient(#fc2342 0%, #ff7f00 100%);
  box-shadow: 0px 0px 0px 2px rgba(255, 166, 93, 0.6);
  border-radius: 25px;
  width: 130px;
  height: 32px;
  font-size: 18px;
  color: #ffffff;
  line-height: 32px;
  text-align: center;
}

.LRTSMG03,
.LRTSMG01 {
  .unit {
    font-weight: 600;
    font-size: 16px;
    color: #000000;
    line-height: 23px;
  }
  .price {
    font-weight: 600;
    font-size: 41px;
    color: #000000;
    line-height: 41px;
    margin-left: 3px;
  }
  .tips {
    background: url(https://common-h5-dev.xiangshuheika.com/static/upload/2025021199ef70279a1d3.png);
    background-repeat: no-repeat;
    background-size: 100%;
    height: 20px;
    width: 66px;
    margin-bottom: 5px;
    margin-left: 3px;
    padding-left: 10px;
    box-sizing: border-box;

    font-size: 10px;
    color: #ffffff;
    line-height: 20px;
    text-align: center;
  }
  .btn {
    margin-top: 8px;
  }
}
.to-use {
  position: absolute;
  background: #e42100;
  border-radius: 12px 0px 0px 12px;
  padding: 3px 3px 4px 8px;
  right: 0;
  top: 12px;

  font-weight: 500;
  font-size: 10px;
  color: #ffffff;
  line-height: 10px;
}
.rule-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(-50%);
  margin-top: 740px;
  margin-left: 50%;
  width: 90px;
  height: 22px;
  border-radius: 11px;
  border: 1px solid #ffffff;
  font-weight: 500;
  color: #ffffff;
}

.rule-wrap {
  margin: 24px 24px 0;
  border-radius: 11px;
  border: 1px solid #ffffff;
  padding: 16px;
  color: #ffffff;
}
</style>